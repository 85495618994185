import type { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import { getEnvUniversal } from '@/utils/env-utils';

const keycloakParams: KeycloakConfig = {
  url: getEnvUniversal('VITE_SSO_URL'),
  realm: getEnvUniversal('VITE_SSO_REALM'),
  clientId: getEnvUniversal('VITE_SSO_CLIENT_ID'),
};

let keycloak: Keycloak;

export const useKeycloak = () => {
  const getInstance = () => {
    if (!keycloak) {
      keycloak = new Keycloak(keycloakParams);
    }

    return keycloak;
  };

  const initAuth = async () => {
    const initParams: KeycloakInitOptions = {
      onLoad: 'check-sso',
      pkceMethod: 'S256',
      enableLogging: true,
    };

    const authenticated = await getInstance().init(initParams);

    if (authenticated) {
      return getInstance();
    }

    return null;
  };

  const initLogin = (redirectUri: string) => {
    const initParams: KeycloakInitOptions = {
      onLoad: 'login-required',
      pkceMethod: 'S256',
      redirectUri,
    };

    return getInstance().login(initParams);
  };

  const initLogout = async (redirectUri: string) => {
    await getInstance().logout({
      redirectUri,
    });
  };

  const updateToken = async (minValidity = 30) => {
    await getInstance().updateToken(minValidity);
  };

  const getToken = () => {
    return getInstance()?.token;
  };

  const getPreferredUsername = () => {
    return getInstance()?.tokenParsed?.preferred_username;
  };

  return {
    initAuth,
    updateToken,
    initLogin,
    initLogout,
    getToken,
    getPreferredUsername,
  };
};
