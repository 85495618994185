import { useLocalStorage } from '@vueuse/core';

// Не стал удалять, чтобы примерно логику оставить
export const enum CATEGORY {
  SSO = 'sso_ab_test',
}

const CHAR_CODE_A = 65;

export const useTestAbc = (name: string) => {
  const current = useLocalStorage<string>(name, '');

  const initTest = (count = 3) => {
    const keys = Array.from(Array(count), (_, i) => String.fromCharCode(CHAR_CODE_A + i));

    if (!current.value || !keys.includes(current.value)) {
      current.value = keys[Math.floor(Math.random() * keys.length)];
    }

    return current.value;
  };

  const isSample = (key: string) => {
    return current.value === key;
  };

  const isTestA = () => isSample('A');
  const isTestB = () => isSample('B');
  const isTestC = () => isSample('C');

  const activateSSO = () => {
    current.value = 'A';
  };

  return {
    initTest,
    isTestA,
    isTestB,
    isTestC,
    activateSSO,
  };
};
